<template>
    <div class="about-us main-page-block">
        <div class="container">
            <div class="row row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-1 row-cols-sm-1 row-cols-1">
                <div class="col col-icon">
                    <div class="icon" id="AboutUsIcons">
                        <img src="~@/assets/img/b-icon.svg" alt="B-Icon" class="item b-icon" :class="iconClass">
                        <img src="~@/assets/img/cup.svg" alt="Cup" class="item cupab" :class="iconClass">
                        <img src="~@/assets/img/star.svg" alt="Star" class="item big-star" :class="iconClass">
                        <img src="~@/assets/img/star.svg" alt="Star" class="item medium-star" :class="iconClass">
                        <img src="~@/assets/img/star.svg" alt="Star" class="item small-star" :class="iconClass">
                        <img src="~@/assets/img/puzzle.svg" alt="Puzzle" class="item puzzle" width="108" :class="iconClass">
                    </div>
                </div>
                <div class="col d-flex align-items-center col-text">
                    <div>
                        <h1>About Us</h1>
                        <p>Brightika Publishing is a team of experts with 10+ years'
                            experience in the gaming industry.</p>
                        <p class="last">We are specializing in publishing and scaling mobile games
                            with IAA/hybrid monetization</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Visibility} from '@/app/services/Visibility'

export default {
    data() {
        return {
            showed: '',
            parallaxed: '',
            iconClass: '',

            elemBIcon: null,
            elemCup: null,
            elemBigStar: null,
            elemMediumStar: null,
            elemSmallStar: null,
            elemPuzzle: null,

            elemBIconRect: null,
            elemCupRect: null,
            elemBigStarRect: null,
            elemMediumStarRect: null,
            elemSmallStarRect: null,
            elemPuzzleRect: null,

            mouse: {
                x: 0,
                y: 0
            }
        }
    },
    mounted() {
        this.init()

        this.windowResize()
        addEventListener('resize', this.windowResize)

        this.handleScroll()
        window.addEventListener('scroll', this.handleScroll, false)
    },
    methods: {
        init: function() {
            this.elemBIcon = document.querySelector('.b-icon')
            this.elemCup = document.querySelector('.cupab')
            this.elemBigStar = document.querySelector('.big-star')
            this.elemMediumStar = document.querySelector('.medium-star')
            this.elemSmallStar = document.querySelector('.small-star')
            this.elemPuzzle = document.querySelector('.puzzle')
        },
        handleScroll: function() {
            console.log(`[${this.iconClass}]`)
            if (this.iconClass.trim() === '') {
                const element = document.getElementById('AboutUsIcons')
                if (Visibility.isVisibleInViewport(element)) {
                    this.showed = 'showed'
                    this.applyIconClass()

                    setTimeout(() => {
                        this.parallaxed = 'parallaxed'
                        this.applyIconClass()
                        addEventListener('mousemove', this.handleParallaxEffect)
                        console.log('add event listner mousemove')
                    }, 2200)
                }
            }
        },
        windowResize: function(event) {
            this.elemBIconRect = {
                left: '50%',
                top: '50%'
            }

            this.elemSmallStarRect = {
                left: '50%',
                top: '24%'
            }

            if (window.innerWidth < 992) {
                this.elemCupRect = {
                    left: '5%',
                    top: '65%'
                }

                this.elemBigStarRect = {
                    left: '80%',
                    top: '85%'
                }

                this.elemMediumStarRect = {
                    left: '12%',
                    top: '20%'
                }

                this.elemPuzzleRect = {
                    left: '80%',
                    top: '10%'
                }
            } else {
                this.elemCupRect = {
                    left: '25%',
                    top: '77%'
                }

                this.elemBigStarRect = {
                    left: '70%',
                    top: '80%'
                }

                this.elemMediumStarRect = {
                    left: '18%',
                    top: '28%'
                }

                this.elemPuzzleRect = {
                    left: '75%',
                    top: '16%'
                }
            }

            this.parallaxEffect()
        },
        applyIconClass: function() {
            this.iconClass = `${this.showed} ${this.parallaxed}`
        },
        handleParallaxEffect: function(e) {
            this.mouse.x = e.clientX
            this.mouse.y = e.clientY

            this.parallaxEffect()
        },
        parallaxEffect() {
            const xMultiplierA = 0.035
            const yMultiplierA = 0.035

            const xMultiplierB = 0.07
            const yMultiplierB = 0.07

            const w = window.innerWidth / 2
            const h = window.innerHeight / 2

            const mouseXA = (this.mouse.x - w) * xMultiplierA
            const mouseYA = (this.mouse.y - h) * yMultiplierA

            const mouseXB = (this.mouse.x - w) * xMultiplierB
            const mouseYB = (this.mouse.y - h) * yMultiplierB

            this.elemBIcon.style.top = `calc(${this.elemBIconRect.top} - ${mouseYA}px)` // + 52
            this.elemBIcon.style.left = `calc(${this.elemBIconRect.left} - ${mouseXA}px)` // + 50

            this.elemCup.style.top = `calc(${this.elemCupRect.top} - ${mouseYB}px)` // + 52
            this.elemCup.style.left = `calc(${this.elemCupRect.left} - ${mouseXB}px)` // + 50

            this.elemBigStar.style.top = `calc(${this.elemBigStarRect.top} - ${mouseYB}px)` // + 36
            this.elemBigStar.style.left = `calc(${this.elemBigStarRect.left} - ${mouseXB}px)` // + 33

            this.elemMediumStar.style.top = `calc(${this.elemMediumStarRect.top} - ${mouseYB}px)` // + 36
            this.elemMediumStar.style.left = `calc(${this.elemMediumStarRect.left} - ${mouseXB}px)` // + 33

            this.elemSmallStar.style.top = `calc(${this.elemSmallStarRect.top} - ${mouseYB}px)` // + 36
            this.elemSmallStar.style.left = `calc(${this.elemSmallStarRect.left} - ${mouseXB}px)` // + 33

            this.elemPuzzle.style.top = `calc(${this.elemPuzzleRect.top} - ${mouseYB}px)` // + 27
            this.elemPuzzle.style.left = `calc(${this.elemPuzzleRect.left} - ${mouseXB}px)` // + 28
        }
    }
}
</script>

<style lang="scss">
@import "~@/app/styles/about-us.scss";
</style>
