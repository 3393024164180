import {createApp} from 'vue'
import App from './App.vue'
import router from './app/router'

(BigInt.prototype as any).toJSON = function() {
    return this.toString()
}

const baseURL = 'https://brightika.com/'
const buildType = process.env.VUE_APP_BUILD_TYPE ?? process.env.NODE_ENV

const app = createApp(App)
app.provide('baseUrl', baseURL)
app.provide('buildType', buildType)
app.use(router)
app.mount('#app')
