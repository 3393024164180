<template>
    <footer class="footer">
        <div class="footer__container">
            <router-link class="footer__logo-link" to="/">
                <img src="~@/assets/img/logo.svg" alt="Brightika" width="200">
            </router-link>
            <div class="footer__copyright-wrapper">
                <ul class="footer__links-list">
                    <li class="footer__links-item">
                        <router-link class="footer__link" to="/privacy-policy/">Privacy Policy</router-link>
                    </li>
                    <li class="footer__links-item">
                        <router-link class="footer__link" to="/terms-of-use/">Terms of Use</router-link>
                    </li>
                </ul>
                <p class="footer__legal-address">
                    700 N. Fairfax suite 614, Alexandria VA, <br> 22314, USA <br>Rahaal 2, Madinat Jumeirah Living, Dubai,<br>UAE
                </p>
                <p class="footer__copyright">
                    © All Rights Reserved. Brightika, Inc.
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "../../styles/footer";
</style>
