import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardSlide = _resolveComponent("CardSlide")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", {
    class: "swiper-box",
    id: $props.galleryID
  }, [
    _createVNode(_component_swiper, {
      slidesPerView: 'auto',
      centeredSlides: true,
      spaceBetween: 12,
      navigation: true,
      mousewheel: true,
      keyboard: true,
      pagination: $setup.pagination,
      modules: $setup.modules,
      preloadImages: false,
      class: "mySwiper"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.slidesData, (slide, key) => {
          return (_openBlock(), _createBlock(_component_swiper_slide, { key: key }, {
            default: _withCtx(() => [
              _createVNode(_component_CardSlide, {
                "card-color-class": slide.cardColorClass,
                "header-head": slide.headerHead,
                "header-text": slide.headerText,
                "footer-head": slide.footerHead,
                "footer-head-small": slide.footerHeadSmall,
                "footer-icon-alt": slide.footerIconAlt,
                "footer-icon-thumbnail-u-r-l": slide.footerIconThumbnailURL,
                "footer-icon-thumbnail-u-r-l2x": slide.footerIconThumbnailURL2x,
                "footer-icon-thumbnail-u-r-l3x": slide.footerIconThumbnailURL3x,
                "footer-icon-text1st": slide.footerIconText1st,
                "footer-icon-text2nd": slide.footerIconText2nd
              }, null, 8, ["card-color-class", "header-head", "header-text", "footer-head", "footer-head-small", "footer-icon-alt", "footer-icon-thumbnail-u-r-l", "footer-icon-thumbnail-u-r-l2x", "footer-icon-thumbnail-u-r-l3x", "footer-icon-text1st", "footer-icon-text2nd"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["pagination", "modules"])
  ], 8, _hoisted_1))
}