<template>
    <section class="callback-form">
        <div class="callback-form__form">
            <ul class="callback-form__fields-list">
                <li class="callback-form__fields-item">
                    <label class="callback-form__label" for="email-input">Your email</label>
                    <input class="callback-form__input" id="email-input" type="email" required=""
                           placeholder="mail@example.com"
                           v-model="email"
                           name="email">
                </li>
                <li class="callback-form__fields-item">
                    <label class="callback-form__label" for="name-input">Your name</label>
                    <input class="callback-form__input" id="name-input" type="text" required=""
                           placeholder="John Smith"
                           v-model="name"
                           name="name">
                </li>
                <li class="callback-form__fields-item">
                    <label class="callback-form__label" for="message-input">Message</label>
                    <textarea class="callback-form__textarea" id="message-input"
                              v-model="message"
                              name="message"></textarea>
                </li>
                <li class="callback-form__fields-item">
                    <div v-if="googleCaptcha" style="z-index: 2000;">
                        <vue-recaptcha
                            sitekey="6LfAi6MkAAAAACKKS0hrV8VDqLiyxT7W87ipzW7W"
                            size="invisible"
                            @verify="recaptchaVerify"
                            @expire="recaptchaExpire"
                            @fail="recaptchaFailure"
                            ref="vueRecaptcha">
                        </vue-recaptcha>
                    </div>
                    <div v-else>
                        <label class="callback-form__label" for="antispam">
                            <img src="/api/captcha.png" alt="Antispam" id="antispam-image" width="70" height="40">
                            Antispam
                        </label>
                        <input class="callback-form__input" id="antispam" type="text" required=""
                               v-model="antispam"
                               name="antispam">
                    </div>
                </li>
            </ul>
            <button class="callback-form__submit-button" @click="onClickSubmit">Submit</button>
        </div>
    </section>
</template>

<script>
import vueRecaptcha from 'vue3-recaptcha2'
import {Events} from '@/app/services/Events'

export default {
    components: {
        vueRecaptcha
    },
    data() {
        return {
            email: '',
            name: '',
            message: '',
            antispam: '',
            googleCaptcha: true
        }
    },
    methods: {
        clearForm: function() {
            // For spamdefender
            // const d = new Date()
            // document
            //     .getElementById('antispam-image')
            //     .setAttribute('src', '/api/captcha.png?' + d.getTime())
            this.email = ''
            this.name = ''
            this.message = ''
            this.antispam = ''
        },
        onClickSubmit() {
            window.grecaptcha.execute()
        },
        recaptchaVerify(token) {
            this.antispam = token
            this.sendForm()
        },
        recaptchaExpire() {
            this.$refs.vueRecaptcha.reset()
        },
        recaptchaFailure() {
            const notification = window.notifier.notify(
                'error',
                'Wrong ReCaptcha'
            )
            notification.push()
        },
        sendForm: function() {
            console.log('Submit form')

            fetch('/api/submit-form-google.php', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: this.name,
                    email: this.email,
                    message: this.message,
                    antispam: this.antispam
                })
            })
                .then(response => response.json())
                .then((response) => {
                    if (response.success) {
                        Events.registerEvent('Submit_game', {
                            click: true,
                            success: true,
                            name: this.name,
                            email: this.email,
                            message: this.message
                        })
                        const notification = window.notifier.notify(
                            'success',
                            'Your submission has been received'
                        )
                        notification.push()
                    } else {
                        Events.registerEvent('Submit_game', {
                            click: true,
                            success: false,
                            error: response.error,
                            name: this.name,
                            email: this.email,
                            message: this.message
                        })
                        const notification = window.notifier.notify(
                            'error',
                            '<b>Error</b> ' + response.error
                        )
                        notification.push()
                    }

                    this.clearForm()
                })
        }
    }
}
</script>

<style lang="scss">
@import "~@/app/styles/callback-form.scss";
</style>
