<template>
    <LogoHeader />
    <SwiperBox galleryID="game-gallery" :slides="slides"/>
    <ContactUs />
</template>

<script>
import LogoHeader from '@/app/pages/abudhabi24/pkg/LogoHeader.vue'
import SwiperBox from '@/app/common/pkg/content/pkg/swiperBox/SwiperBox.vue'
import ContactUs from '@/app/common/pkg/content/pkg/contactUs/ContactUs.vue'

export default {
    name: 'AbuDhabi24',
    components: {ContactUs, SwiperBox, LogoHeader},
    data() {
        return {
            slides: [
                {
                    cardColorClass: 'card-red card-1',
                    headerHead: 'Average Metrics,<br/>BAD ROI?',
                    headerText: 'Everybody wants hits. But who said marginal performance is a death sentence? Even a project with terrible retention can be successful with us. Turn your break-even project into a breakthrough!',
                    footerHead: '14%',
                    footerHeadSmall: 'Day 1<br/>retention',
                    footerIconThumbnailURL: require('@/assets/img/belgrade23/icons/image1.png'),
                    footerIconThumbnailURL2x: require('@/assets/img/belgrade23/icons/image1@2x.png'),
                    footerIconThumbnailURL3x: require('@/assets/img/belgrade23/icons/image1@3x.png'),
                    footerIconAlt: 'Save the Bro',
                    footerIconText1st: 'Save the Bro',
                    footerIconText2nd: '<b>33M+</b> Downloads'
                },
                {
                    cardColorClass: 'card-black card-2',
                    headerHead: 'UNLOCK the power<br/>of alternative stores',
                    headerText: 'With ever more crowded markets, it’s time to look to alternative stores. Unprofitable mobile projects achieved success on Amazon and Microsoft Store with our expertise.',
                    footerHead: '200%',
                    footerHeadSmall: '<br/>ROAS',
                    footerIconThumbnailURL: require('@/assets/img/belgrade23/icons/image2.png'),
                    footerIconThumbnailURL2x: require('@/assets/img/belgrade23/icons/image2@2x.png'),
                    footerIconThumbnailURL3x: require('@/assets/img/belgrade23/icons/image2@3x.png'),
                    footerIconAlt: 'Differences Online',
                    footerIconText1st: 'Differences Online',
                    footerIconText2nd: '<b>500K+</b> Downloads'
                },
                {
                    cardColorClass: 'card-red card-3',
                    headerHead: 'Make iOS<br/>great again!',
                    headerText: 'After ATT apocalypse, many focused all their marketing efforts on Android, losing lion’s share of the revenue. We have cracked iOS multi-channel marketing and are ready to boost your profits!',
                    footerHead: '280%',
                    footerHeadSmall: 'Profit<br/>Increase',
                    footerIconThumbnailURL: require('@/assets/img/belgrade23/icons/image3.png'),
                    footerIconThumbnailURL2x: require('@/assets/img/belgrade23/icons/image3@2x.png'),
                    footerIconThumbnailURL3x: require('@/assets/img/belgrade23/icons/image3@3x.png'),
                    footerIconAlt: 'IQ Boost',
                    footerIconText1st: 'IQ Boost',
                    footerIconText2nd: '<b>23M+</b> Downloads'
                },
                {
                    cardColorClass: 'card-black card-4',
                    headerHead: 'Your project<br/>got abandoned?',
                    headerText: 'Once investing significantly, your publisher now cuts the budgets, and the project brings in almost nothing. Find your second wind with us and turn profitable again.',
                    headerCoverImage: '',
                    footerHead: '$1M',
                    footerHeadSmall: 'extra profit after<br/>big publisher',
                    footerIconThumbnailURL: require('@/assets/img/belgrade23/icons/image4.png'),
                    footerIconThumbnailURL2x: require('@/assets/img/belgrade23/icons/image4@2x.png'),
                    footerIconThumbnailURL3x: require('@/assets/img/belgrade23/icons/image4@3x.png'),
                    footerIconAlt: 'Will It Crush',
                    footerIconText1st: 'Will It Crush',
                    footerIconText2nd: '<b>10M+</b> Downloads'
                }
            ]
        }
    }
}
</script>

<style lang="scss">
</style>
