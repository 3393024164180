<template>
    <div class="portfolio main-page-block">
        <h1>Portfolio</h1>
        <div class="container">
            <div class="row row-cols-xxl-3 row-cols-xl-3 row-cols-lg-2 row-cols-md-1 row-cols-sm-1 row-cols-xs-1 row-cols-1">
                <div class="col">
                    <PortfolioGameCard
                        header="Can you spot it?"
                        downloads="25M+"
                        :icon="require('@/assets/img/game-can-you-spot.png')"
                        google-play-link="https://play.google.com/store/apps/details?id=com.miniit.spotit"
                        app-store-link="https://apps.apple.com/us/app/can-you-spot-it-try/id1499114408"
                    />
                </div>
                <div class="col">
                    <PortfolioGameCard
                        header="Will It Crush?"
                        downloads="25M+"
                        :icon="require('@/assets/img/game-will-crush.png')"
                        google-play-link="https://play.google.com/store/apps/details?id=com.dimchi.crush"
                        app-store-link="https://apps.apple.com/us/app/will-it-crush/id1347072403"
                    />
                </div>
                <div class="col">
                    <PortfolioGameCard
                        header="Save the Bro"
                        downloads="35M+"
                        :icon="require('@/assets/img/game-save-bro.png')"
                        google-play-link="https://play.google.com/store/apps/details?id=com.miniit.save.the.guy.game"
                        app-store-link="https://apps.apple.com/us/app/save-the-bro/id1522693933"
                    />
                </div>
                <div class="col">
                    <PortfolioGameCard
                        header="IQ Boost"
                        downloads="25M+"
                        :icon="require('@/assets/img/game-iq-boost.png')"
                        google-play-link="https://play.google.com/store/apps/details?id=com.miniit.brain"
                        app-store-link="https://apps.apple.com/us/app/id1518047412"
                    />
                </div>
                <div class="col">
                    <router-link class="game-card-empty card-box d-flex align-items-center justify-content-center" :to="{ path: '/', hash: '#contact' }" @click="routeToAnchorContact">
                        <span>+ Your Game</span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PortfolioGameCard from '@/app/pages/mainPage/pkg/PortfolioGameCard.vue'
import {Events} from '@/app/services/Events'

export default {
    components: {PortfolioGameCard},
    methods: {
        routeToAnchorContact() {
            Events.registerEvent('Add_your_game', {
                click: true
            })
        }
    }
}
</script>

<style lang="scss">
@import "~@/app/styles/main-page-blocks.scss";
@import "~@/app/styles/portfolio.scss";
</style>
