<template>
    <header class="header">
        <nav class="header__navigation">
            <ul class="header__nav-list">
                <li class="header__nav-item header__nav-item--arrow">
                    <router-link class="header__arrow-button" to="/">
                        <img class="header__arrow-icon" src="~@/assets/img/icon-chevron-left.svg" alt="Go to main page">
                    </router-link>
                </li>
                <li class="header__nav-item header__nav-item--logo">
                    <router-link class="header__logo-link" to="/">
                        <img class="header__logo" src="~@/assets/img/logo.svg" alt="Brightika">
                    </router-link>
                </li>
                <li class="header__nav-item header__nav-item--contacts">
                    <router-link class="header__contacts-link" :to="{ path: '/', hash: '#contact' }">Contacts</router-link>
                </li>
            </ul>
        </nav>
    </header>
</template>

<script>
export default {
}
</script>

<style lang="scss">
@import "../../styles/header";
</style>
