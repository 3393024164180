<template>
    <WePublishGames />
    <ScaleYourGame />
    <AboutUs />
    <PortfolioGames />
    <WhatWeOffer />
    <ContactUs />
</template>

<script>
import WePublishGames from '@/app/pages/mainPage/pkg/WePublishGames.vue'
import ScaleYourGame from '@/app/pages/mainPage/pkg/ScaleYourGame.vue'
import AboutUs from '@/app/pages/mainPage/pkg/AboutUs.vue'
import PortfolioGames from '@/app/pages/mainPage/pkg/PortfolioGames.vue'
import WhatWeOffer from '@/app/pages/mainPage/pkg/WhatWeOffer.vue'
import ContactUs from '@/app/pages/mainPage/pkg/ContactUs.vue'

export default {
    components: {WePublishGames, ScaleYourGame, AboutUs, PortfolioGames, WhatWeOffer, ContactUs}
}
</script>

<style lang="scss">

</style>
