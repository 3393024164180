import {createRouter, createWebHistory} from 'vue-router'
import NotFound from '@/app/pages/notFound/NotFound.vue'
import MainPage from '@/app/pages/mainPage/MainPage.vue'
import TermOfUse from '@/app/pages/termOfUse/TermOfUse.vue'
import PrivacyPolicy from '@/app/pages/privacyPolicy/PrivacyPolicy.vue'
import PrivacyPolicyCn from '@/app/pages/privacyPolicyCn/PrivacyPolicyCn.vue'
import AbuDhabi24 from '@/app/pages/abudhabi24/AbuDhabi24.vue'

const routes = [
    {
        path: '/',
        component: MainPage,
        name: 'main-page'
    },
    {
        path: '/abudhabi24',
        component: AbuDhabi24,
        name: 'abudhabi24'
    },
    {
        path: '/terms-of-use/',
        component: TermOfUse,
        name: 'term-of-use'
    },
    {
        path: '/privacy-policy/',
        component: PrivacyPolicy,
        name: 'privacy-policy'
    },
    {
        path: '/privacy-policy-cn/',
        component: PrivacyPolicyCn,
        name: 'privacy-policy-cn'
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFound,
        name: 'notFound'
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if ((to.hash !== null) && (to.hash.length > 0)) {
            return {
                el: to.hash,
                behavior: 'smooth'
            }
        }

        return {top: 0}
    }
})

export default router
