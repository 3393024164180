<template>
    <div class="what-we-offer main-page-block">
        <h1>What We Offer</h1>
        <div class="container">
            <div class="row">
                <div class="col-lg">
                    <img src="~@/assets/img/coin.svg" alt="Coin" class="coin">
                    <h2>Monetization &amp;<br /> Analysis</h2>
                    <p>Efficient ad mediation setup and deep in-app analytics check.
                        We test your prototypes on real users.
                        Data-driven profit maximization strategy.</p>
                </div>
                <div class="col-lg">
                    <img src="~@/assets/img/user-icon.svg" alt="User" class="user">
                    <h2>User-Acquisition<br /> Expertise</h2>
                    <p>We are working with Facebook, Google, Unity ads, Snapchat, TikTok, IAP Networks, with budgets for testing and scaling.
                        User acquisition is fully transparent with developers having access to the ad accounts and all the statistics.</p>
                </div>
                <div class="col-lg">
                    <img src="~@/assets/img/video-icon.svg" alt="Video" class="video">
                    <h2>In-House Ad<br /> Creatives Production</h2>
                    <p>Data-driven approach to high-quality ad creatives production.
                        We constantly analyze ad creatives so we know how to make the creatives that succeed.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "~@/app/styles/main-page-blocks.scss";
@import "~@/app/styles/what-we-offer.scss";
</style>
