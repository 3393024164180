<template>
    <div class="contact-us main-page-block">
        <div class="container">
            <div class="row row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-1">
                <div class="col col-text">
                    <h1 id="contact">Contact Us</h1>
                    <p>Want us to publish your game?
                        You have any questions?
                        Fill out this form and we will contact you shortly.</p>
                </div>
                <div class="col col-form">
                    <CallbackForm/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CallbackForm from '@/app/pages/mainPage/pkg/CallbackForm.vue'

export default {
    components: {CallbackForm}
}
</script>

<style lang="scss">
@import "~@/app/styles/contact-us.scss";
</style>
