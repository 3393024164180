
export default {
    props: {
        icon: {
            type: String,
            required: true
        },
        header: {
            type: String,
            required: true
        },
        downloads: {
            type: String,
            required: true
        },
        appStoreLink: {
            type: String,
            required: true
        },
        googlePlayLink: {
            type: String,
            required: true
        }
    }
}
