<template>
    <div :class="`card-slide ${cardColorClass}`">
        <div class="card-slide-header">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h1 v-html="headerHead"></h1>
                        <p v-html="headerText"></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-slide-footer">
            <div class="container">
                <div class="row">
                    <div class="col-auto d-flex flex-column justify-content-center"><h1 v-html="footerHead"></h1></div>
                    <div class="col d-flex flex-column justify-content-center"><h2 v-html="footerHeadSmall"></h2></div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-auto d-flex flex-column justify-content-center">
                        <img
                                :data-src="footerIconThumbnailURL"
                                :data-srcset="`${footerIconThumbnailURL} 1x, ${footerIconThumbnailURL2x} 2x, ${footerIconThumbnailURL3x} 3x`"
                                :alt="footerIconAlt"
                                width="48"
                                height="48"
                                class="lazyload"
                        />
                    </div>
                    <div class="col d-flex flex-column justify-content-center">
                        <p class="first" v-html="footerIconText1st"></p>
                        <p class="second" v-html="footerIconText2nd"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CardSlide',
    props: {
        cardColorClass: {
            type: String,
            required: true
        },
        headerHead: {
            type: String,
            required: true
        },

        headerText: {
            type: String,
            required: true
        },
        footerHead: {
            type: String,
            required: true
        },
        footerHeadSmall: {
            type: String,
            required: true
        },
        footerIconText1st: {
            type: String,
            required: true
        },
        footerIconText2nd: {
            type: String,
            required: true
        },
        footerIconThumbnailURL: {
            type: String,
            required: true
        },
        footerIconThumbnailURL2x: {
            type: String,
            required: true
        },
        footerIconThumbnailURL3x: {
            type: String,
            required: true
        },
        footerIconAlt: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss">
@import "~@/app/styles/card-slide.scss";
</style>
