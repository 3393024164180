<template>
    <LayoutHeader />
    <main class="main-content">
        <h1 class="main-content__heading">私隐政策</h1>
        <p class="main-content__subtitle">最后更新日期:2022年6月24日</p>
        <section class="main-content__text-block text-block">
            <p class="text-block__text">
                Brightika, Inc. ("Brightika, Inc."、"我们"、"我们"或"我们的"）经营网站、游戏和移动应用程序（"服务"）。
            </p>
            <p class="text-block__text text-block__text--double-margin">
                当您使用我们的服务时，本页会告知您有关收集、使用和披露个人资料的政策，以及您与该等资料有关的选择。
            </p>
            <p class="text-block__text text-block__text--double-margin">
                我们使用您的数据来提供和改进服务。 通过使用该服务，您同意根据本政策收集和使用信息。 除非本隐私政策另有规定，否则本隐私政策中使用的术语与我们的条款和条件具有相同的含义。
            </p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold">定义</p>
            <p class="text-block__text text-block__text--double-margin">服务</p>
            <p class="text-block__text">服务是指由Brightika, Inc.运营的网站和移动应用程序。 </p>
            <p class="text-block__text text-block__text--double-margin">个人资料</p>
            <p class="text-block__text">个人数据是指可以从这些数据（或我们拥有或可能拥有的这些信息和其他信息）中识别的有关活着的个人的数据。</p>
            <p class="text-block__text text-block__text--double-margin">使用资料</p>
            <p class="text-block__text">使用数据是通过使用服务或从服务基础设施本身自动收集的数据（例如，页面访问的持续时间）。</p>
            <p class="text-block__text text-block__text--double-margin">曲奇饼</p>
            <p class="text-block__text">Cookie是存储在您的设备（计算机或移动设备）上的小块数据。</p>
            <p class="text-block__text text-block__text--double-margin">数据控制器</p>
            <p class="text-block__text">数据控制者是指自然人或法人（单独或共同或与其他人共同）确定处理或处理任何个人信息的目的和方式。</p>
            <p class="text-block__text text-block__text--double-margin">就本隐私政策而言，我们是您个人数据的数据控制者。</p>
            <p class="text-block__text text-block__text--double-margin">数据处理者（或服务提供商</p>
            <p class="text-block__text">数据处理者（或服务提供商）是指代表数据控制者处理数据的任何自然人或法人。</p>
            <p class="text-block__text text-block__text--double-margin">我们可能会使用各种服务提供商的服务，以便更有效地处理您的数据。</p>
            <p class="text-block__text text-block__text--double-margin">资料当事人(或使用者)</p>
            <p class="text-block__text">数据主体是使用我们服务的任何活着的个人，并且是个人数据的主体。</p>
            <p class="text-block__text text-block__text--double-margin">资料收集及使用</p>
            <p class="text-block__text">我们出于各种目的收集几种不同类型的信息，以便为您提供和改进我们的服务。</p>
            <p class="text-block__text text-block__text--double-margin">收集的数据类型</p>
            <p class="text-block__text text-block__text--double-margin">个人资料</p>
            <p class="text-block__text">在使用我们的服务时，我们可能会要求您向我们提供某些可用于联系或识别您的个人身份信息（"个人数据"）。 个人身份信息可能包括但不限于:</p>
            <ul class="text-block__list">
                <li class="text-block__item">
                    <p class="text-block__text">电邮地址</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">名字和姓氏</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">您向服务发送的消息（例如聊天记录和支持票证）</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">您选择提供给我们的其他数据（例如用于识别您对服务的问题或识别丢失帐户的数据）</p>
                </li>
            </ul>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold">Cookie和使用数据</p>
            <p class="text-block__text text-block__text--double-margin">使用资料</p>
            <p class="text-block__text text-block__text--double-margin">当您访问我们的服务或通过移动设备访问服务时，我们也可能收集您的浏览器发送的信息（"使用数据"）。</p>
            <p class="text-block__text text-block__text--double-margin">此使用数据可能包括您的计算机的互联网协议地址（例如IP地址），浏览器类型，浏览器版本，您访问的我们服务的页面，访问的时间和日期，在这些页面上花费的时间，唯一设备标识符和其他诊断数据等信息。</p>
            <p class="text-block__text text-block__text--double-margin">当您通过移动设备或通过移动设备访问服务时，此使用数据可能包括以下信息：您使用的移动设备类型、您的移动设备唯一ID、您的移动设备的IP地址、您的移动操作系统、您使用的移动互联网浏览器类型、唯一设备标识符和其他诊断数据。</p>
            <p class="text-block__text text-block__text--double-margin">当您使用该服务时，我们还会为您创建自己的特定ID。</p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold">跟踪Cookie数据</p>
            <p class="text-block__text text-block__text--double-margin">我们使用cookie和类似的跟踪技术来跟踪我们服务上的活动并保存某些信息。</p>
            <p class="text-block__text text-block__text--double-margin">Cookie是包含少量数据的文件，其中可能包含匿名唯一标识符。 Cookie从网站发送到您的浏览器并存储在您的设备上。 跟踪技术还用于收集和跟踪信息以及改进和分析我们的服务的信标、标签和脚本。</p>
            <p class="text-block__text text-block__text--double-margin">您可以指示您的浏览器拒绝所有cookie或指示何时发送cookie。 但是，如果您不接受cookie，您可能无法使用我们服务的某些部分。</p>
            <p class="text-block__text text-block__text--quarter-margin">我们使用的Cookie示例:</p>
            <ul class="text-block__list">
                <li class="text-block__item">
                    <p class="text-block__text">会话Cookie。 我们使用会话Cookie来操作我们的服务。</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">偏好饼干。 我们使用首选项Cookie来记住您的首选项和各种设置。</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">安全Cookie。 我们出于安全目的使用安全Cookie。</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">广告饼干。 广告Cookie用于为您提供可能与您和您的兴趣相关的广告。</p>
                </li>
            </ul>
            <p class="text-block__text text-block__text--quarter-margin">第三方数据</p>
            <p class="text-block__text">我们从合作伙伴处收到的数据。 这些数据可能包括如果您将第三方服务（例如Meta，Google）与服务链接，我们收到的信息，来自服务运行平台的信息（例如验证付款），用于广告和分析目的的信息。</p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold">资料的使用</p>
            <p class="text-block__text text-block__text--double-margin">Brightika, Inc.将收集到的数据用于各种目的:</p>
            <ul class="text-block__list">
                <li class="text-block__item">
                    <p class="text-block__text">提供及维持我们的服务</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">通知您有关我们服务的更改</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">当您选择参与我们服务的互动功能时，允许您参与我们服务的互动功能</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">提供客户支持</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">收集分析或有价值的信息，以便我们改进我们的服务</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">监控我们服务的使用情况</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">检测、预防和解决技术问题</p>
                </li>
            </ul>
            <p class="text-block__text text-block__text--double-margin">根据《通用数据保护条例》处理个人数据的法律依据</p>
            <p class="text-block__text text-block__text--double-margin">如果您来自欧洲经济区（EEA），Brightika, Inc.收集和使用本隐私政策中描述的个人信息的法律依据取决于我们收集的个人数据以及我们收集数据的具体背景。</p>
            <p class="text-block__text text-block__text--double-margin">Brightika, Inc. 可能会处理您的个人数据，因为:</p>
            <ul class="text-block__list">
                <li class="text-block__item">
                    <p class="text-block__text">我们需要履行与您的合同</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">处理符合我们的合法利益，不会被您的权利所复盖</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">用于付款处理目的</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">我们需要履行与您的合同</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">遵守法律。</p>
                </li>
            </ul>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold">保留资料</p>
            <p class="text-block__text text-block__text--double-margin">Brightika, Inc. 只会为本私隐政策所载的目的保留你的个人资料。 我们将在必要的范围内保留和使用您的个人数据，以履行我们的法律义务（例如，如果我们需要保留您的数据以遵守适用的法律），解决争议并执行我们的。</p>
            <p class="text-block__text text-block__text--double-margin">Brightika, Inc. 亦会保留使用资料作内部分析之用。 使用数据通常会保留较短的时间，除非这些数据用于加强安全性或改进我们服务的功能，或者我们在法律上有义务将这些数据保留更长的时间。</p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold">资料的转移</p>
            <p class="text-block__text text-block__text--double-margin">您的信息，包括个人数据，可能会被转移到位于您所在州，省，国家或其他政府管辖区以外的计算机上并保存，其中数据保护法可能与您所在司法管辖区的</p>
            <p class="text-block__text text-block__text--double-margin">您同意本隐私政策，然后提交此类信息，即表示您同意该转移。</p>
            <p class="text-block__text text-block__text--double-margin">Brightika, Inc. 将采取一切合理必要的步骤，以确保您的数据得到安全处理并符合本隐私政策，除非有足够的控制措施，包括您的数据和其他个人信息的安全性，否则。</p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold">资料的披露</p>
            <p class="text-block__text text-block__text--double-margin">执法披露</p>
            <p class="text-block__text">在某些情况下，Brightika，Inc。 如果法律要求或应公共当局（例如法院或政府机构）的有效要求，可能需要披露您的个人数据。</p>
            <p class="text-block__text text-block__text--double-margin">法律规定</p>
            <p class="text-block__text text-block__text--double-margin">Brightika, Inc. 在善意相信有必要采取此类行动的情况下，可能会披露您的个人数据:</p>
            <ul class="text-block__list">
                <li class="text-block__item">
                    <p class="text-block__text">遵守法律义务</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">保护和捍卫Brightika, Inc.的权利或财产</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">防止或调查与服务有关的可能不当行为</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">保障服务使用者或公众的人身安全</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">防止法律责任</p>
                </li>
            </ul>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold">资料的保安</p>
            <p class="text-block__text text-block__text--double-margin">为了帮助确保您的数据安全，我们正在制定和实施管理，技术和物理安全措施，以保护您的数据免受未经授权的访问或丢失，滥用或更改。</p>
            <p class="text-block__text text-block__text--double-margin">您的数据的安全性对我们很重要，但请记住，没有任何通过互联网传输的方法或电子存储方法是100％安全的。 虽然我们努力使用商业上可接受的方式来保护您的个人数据，但我们无法保证其绝对安全。</p>
            <p class="text-block__text text-block__text--quarter-margin">"不跟踪"信号</p>
            <p class="text-block__text text-block__text--double-margin">我们不支持不跟踪（"DNT"）。 不跟踪是您可以在web浏览器中设置的首选项，以通知网站您不希望被跟踪。</p>
            <p class="text-block__text text-block__text--double-margin">您可以通过访问web浏览器的首选项或设置页面来启用或禁用不跟踪。</p>
            <p class="text-block__text text-block__text--double-margin">您可以通过检查Android或iOS设备的隐私设置并选择"限制广告跟踪"(Apple iOS)或"选择退出基于兴趣的广告"(Android)来选择退出移动应用程序上的基于兴趣的广告。</p>
            <p class="text-block__text text-block__text--quarter-margin">《通用数据保护条例》下的数据保护权利</p>
            <p class="text-block__text text-block__text--double-margin">如果您是欧洲经济区（EEA）的居民，您拥有某些数据保护权利。 Brightika, Inc.旨在采取合理步骤，让你更正、修订、删除或限制使用你的个人资料。</p>
            <p class="text-block__text text-block__text--double-margin">如果您希望了解我们持有的关于您的个人数据，以及您希望将其从我们的系统中删除，请与我们联系。</p>
            <p class="text-block__text text-block__text--double-margin">在某些情况下，您拥有以下数据保护权利:</p>
            <ul class="text-block__list">
                <li class="text-block__item">
                    <p class="text-block__text">访问、更新或删除我们拥有的关于您的信息的权利。</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">只要有可能，您可以访问，更新或请求删除您的个人数据。 要执行这些操作，请与我们联系以帮助您。</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">纠正的权利。 如果您的信息不准确或不完整，您有权更正您的信息。</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">反对的权利。 您有权反对我们处理您的个人数据。</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">限制权。 您有权要求我们限制处理您的个人信息。</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">数据可移植性的权利。 您有权获得我们以结构化、机器可读和常用格式提供的关于您的信息的副本。</p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">撤回同意的权利。 如果Brightika, Inc.依赖您的同意处理您的个人信息，您也有权随时撤回您的同意。</p>
                </li>
            </ul>
            <p class="text-block__text text-block__text--double-margin">请注意，我们可能会要求您在回复此类请求之前验证您的身份。</p>
            <p class="text-block__text text-block__text--double-margin">您有权向数据保护机构投诉我们收集和使用您的个人数据。 欲了解更多信息，请联系您在欧洲经济区（EEA）当地的数据保护机构。</p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold">服务供应商</p>
            <p class="text-block__text text-block__text--double-margin">我们可能雇用第三方公司和个人来促进我们的服务（"服务提供商"），代表我们提供服务，执行与服务相关的服务或协助我们分析我们的服务的使用方式</p>
            <p class="text-block__text text-block__text--double-margin">这些第三方访问您的个人数据仅用于代表我们执行这些任务，并且有义务不披露或将其用于任何其他目的。</p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold">分析法,分析法</p>
            <p class="text-block__text">我们可能会使用第三方服务提供商来监控和分析我们服务的使用情况。</p>
            <p class="text-block__text text-block__text--double-margin">谷歌分析</p>
            <p class="text-block__text">谷歌分析是谷歌提供的一个网络分析服务，跟踪和报告网站流量。 Google使用收集的数据来跟踪和监控我们服务的使用情况。 这些数据与其他Google服务共享。 Google可能会使用收集到的数据对其自己的广告网络的广告进行上下文化和个性化。</p>
            <p class="text-block__text">有关Google隐私惯例的更多信息，请访问<a class="text-block__inline-link" href="https://policies.google.com/privacy?hl=en">Google隐私条款网页</a></p>
            <p class="text-block__text text-block__text--double-margin">振幅</p>
            <p class="text-block__text">振幅是一种分析服务.</p>
            <p class="text-block__text">我们还鼓励您查看 <a class="text-block__inline-link" href="https://amplitude.com/privacy">Amplitude政策</a> 以保护您的数据</p>
            <p class="text-block__text text-block__text--double-margin">AppMetrica</p>
            <p class="text-block__text">阿普梅特里卡</p>
            <p class="text-block__text">AppMetrica是由YANDEX，Oy提供的分析服务。</p>
            <p class="text-block__text">我们还鼓励您查看 <a class="text-block__inline-link" href="https://yandex.ru/legal/confidential/">AppMetrica政策</a>，以保护您的数据</p>
            <p class="text-block__text text-block__text--double-margin">AppsFlyer应用程序</p>
            <p class="text-block__text">AppsFlyer是AppsFlyer有限公司提供的分析服务/</p>
            <p class="text-block__text">我们还鼓励您查看 <a class="text-block__inline-link" href="https://www.appsflyer.com/privacy-policy/">AppsFlyer政策</a>，以保护您的数据安全</p>
            <p class="text-block__text text-block__text--double-margin">调整</p>
            <p class="text-block__text">Adjust是Adjust GmbH提供的分析服务。</p>
            <p class="text-block__text">我们还鼓励您查看 <a class="text-block__inline-link" href="https://www.adjust.com/terms/privacy-policy/">Adjust政策</a> 以保护您的数据</p>
            <!-- <p class="text-block__text text-block__text--double-margin">Kochava</p>
            <p class="text-block__text">We collect the following categories of your personal information and may sell it to third parties: identifiers, commercial information, internet or other electronic network activity information, geolocation data, and inferences drawn from these categories.</p>
            <p class="text-block__text">We also encourage you to review the <a class="text-block__inline-link" href="https://www.kochava.com/support-privacy/">Kochava policy</a> for safeguarding your data</p> -->
            <p class="text-block__text text-block__text--double-margin">Unity分析</p>
            <p class="text-block__text">Unity Analytics由Unity Technologies提供。</p>
            <p class="text-block__text">我们还鼓励您查看 <a class="text-block__inline-link" href="https://unity3d.com/legal/privacy-policy">Unity Technologies政策</a>，以保护您的数据</p>
            <p class="text-block__text text-block__text--double-margin">Meta产品</p>
            <p class="text-block__text">Meta产品由Meta公司提供。 和Meta爱尔兰有限公司。</p>
            <p class="text-block__text">我们还鼓励您查看 <a class="text-block__inline-link" href="https://www.facebook.com/privacy/explanation">Meta的数</a> 据保护政策</p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold">广告业</p>
            <p class="text-block__text text-block__text--double-margin">我们可能会使用第三方服务提供商向您展示广告，以帮助支持和维护我们的服务。</p>
            <p class="text-block__text text-block__text--double-margin">Google AdMob</p>
            <p class="text-block__text">Google AdMob由Google Inc.提供。</p>
            <p class="text-block__text">您可以按照Google描述的说明选择退出 <a class="text-block__inline-link" href="https://support.google.com/ads/answer/2662922?hl=en">Google AdMob</a> 服务。</p>
            <p class="text-block__text">有关Google如何使用收集的信息的更多信息，请访问 <a class="text-block__inline-link" href="http://www.google.com/policies/privacy/partners/">Google</a> 在您使用我们合作伙伴的网站或应用程序页面时如何使用数据，或访问 <a class="text-block__inline-link" href="http://www.google.com/policies/privacy/">Google</a> 的隐私政策。</p>
            <p class="text-block__text text-block__text--double-margin">AppLovin</p>
            <p class="text-block__text">AppLovin由AppLovin公司提供。</p>
            <p class="text-block__text">如果您想选择退出AppLovin服务，请访问 <a class="text-block__inline-link" href="https://www.applovin.com/optout">AppLovin</a> 选择退出页面。</p>
            <p class="text-block__text">如果您想了解有关AppLovin的更多信息，请访问AppLovin的 <a class="text-block__inline-link" href="https://www.applovin.com/privacy">隐私政策</a> 页面。</p>
            <p class="text-block__text text-block__text--double-margin">Unity Ads</p>
            <p class="text-block__text">Unity Ads由Unity Technologies提供。</p>
            <p class="text-block__text">如果您想了解有关Unity Ads的更多信息，请访问Unity Technologies的 <a class="text-block__inline-link" href="https://unity3d.com/legal/privacy-policy">隐私政策</a> 页面。</p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold">行为评论</p>
            <p class="text-block__text text-block__text--double-margin">Brightika, Inc. 在您访问我们的服务后，使用再营销服务在第三方网站上向您做广告。 我们和我们的第三方供应商使用cookie根据您过去访问我们的服务来通知、优化和提供广告。</p>
            <p class="text-block__text text-block__text--double-margin">Google AdWords</p>
            <p class="text-block__text">Google AdWords再营销服务由Google Inc.提供。</p>
            <p class="text-block__text">您可以选择退出谷歌分析显示广告和自定义谷歌显示网络广告访问 <a class="text-block__inline-link" href="http://www.google.com/settings/ads">谷歌广告设置</a> 页面。</p>
            <p class="text-block__text">谷歌还建议为你的网页浏览器安装 <a class="text-block__inline-link" href="https://tools.google.com/dlpage/gaoptout">谷歌分析选择退出浏览器插件</a> 谷歌分析选择退出浏览器插件为访问者提供了防止他们的数据被谷歌分析收集和使用的能力。</p>
            <p class="text-block__text">有关Google隐私惯例的更多信息，请访问 <a class="text-block__inline-link" href="http://www.google.com/intl/en/policies/privacy/">Google</a> 隐私条款网页。</p>
            <p class="text-block__text text-block__text--double-margin">Twitter</p>
            <p class="text-block__text">Twitter再营销服务由Twitter Inc.提供。</p>
            <p class="text-block__text">您可以按照 <a class="text-block__inline-link" href="https://support.twitter.com/articles/20170405">Twitter</a> 的说明选择退出基于兴趣的广告。</p>
            <p class="text-block__text">您可以访问Twitter的 <a class="text-block__inline-link" href="https://twitter.com/privacy">隐私政策页面</a>，了解有关Twitter隐私惯例和政策的更多信息。</p>
            <p class="text-block__text text-block__text--double-margin"></p>
            <p class="text-block__text">Meta</p>
            <p class="text-block__text">Meta再营销服务由Meta Inc.提供。 和Meta爱尔兰有限公司。</p>
            <p class="text-block__text">您可以通过访问此 <a class="text-block__inline-link" href="https://www.facebook.com/help/164968693837950">页面</a> 了解有关Meta基于兴趣的广告的更多信息:</p>
            <p class="text-block__text">要选择退出Meta的基于兴趣的广告，请遵循 <a class="text-block__inline-link" href="https://www.facebook.com/help/568137493302217">Meta</a> 的这些说明。</p>
            <p class="text-block__text">Meta遵守数字广告联盟建立的在线行为广告自律原则。您还可以通过 <a class="text-block__inline-link" href="http://www.aboutads.info/choices/">美国</a>, 的数字广告联盟选择退出Meta和其他 参与公司, 加拿大数字广告联盟在 <a class="text-block__inline-link" href="http://youradchoices.ca/">加拿大</a> 或 欧洲互动数字广告联盟在 <a class="text-block__inline-link" href="http://www.youronlinechoices.eu/">在欧洲</a>，或选择退出使用您的移动设备设置。</p>
            <p class="text-block__text">有关Meta隐私惯例的更多信息，请访问 <a class="text-block__inline-link" href="https://www.facebook.com/privacy/explanation">Meta</a> 的数据政策。</p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold">客户支持</p>
            <p class="text-block__text text-block__text--double-margin">Brightika, Inc. 使用第三方客户支持服务在服务内提供客户支持。</p>
            <p class="text-block__text text-block__text--double-margin">Helpshift</p>
            <p class="text-block__text">Helpshift客户支持服务由Helpshift,Inc.提供。</p>
            <p class="text-block__text">您可以访问 <a class="text-block__inline-link" href="https://www.helpshift.com/legal/privacy/">Helpshift</a> 的隐私政策页面，了解有关其隐私惯例和政策的更多信息</p>
            <p class="text-block__text text-block__text--double-margin">Zendesk</p>
            <p class="text-block__text">Zendesk客户支持服务由Zendesk,Inc.提供.</p>
            <p class="text-block__text">你可浏览 <a class="text-block__inline-link" href="https://www.zendesk.com/company/customers-partners/privacy-policy/">Zendesk</a> 的私隐政策网页，了解更多有关私隐实务和政策的资料</p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold">付款</p>
            <p class="text-block__text text-block__text--double-margin">我们可能会在服务范围内提供付费产品和/或服务。 在这种情况下，我们使用第三方服务进行支付处理（例如支付处理器）。</p>
            <p class="text-block__text text-block__text--double-margin">我们不会存储或收集您的支付卡详细信息。 该信息直接提供给我们的第三方支付处理器，其对您的个人信息的使用受其隐私政策的约束。 这些支付处理器遵循PCI-DSS制定的标准，由PCI安全标准委员会管理，这是Visa，Mastercard，American Express和Discover等品牌的共同努力。 PCI-DSS要求有助于确保支付信息的安全处理。</p>
            <p class="text-block__text text-block__text--double-margin">我们合作的支付处理器是:</p>
            <p class="text-block__text text-block__text--double-margin">苹果商店应用内付款</p>
            <p class="text-block__text">他们的隐私政策可以在 <a class="text-block__inline-link" href="https://www.apple.com/legal/privacy/en-ww/">这里</a> 查看。</p>
            <p class="text-block__text text-block__text--double-margin">Google Play应用内支付</p>
            <p class="text-block__text">他们的隐私政策可以在 <a class="text-block__inline-link" href="https://www.google.com/policies/privacy/">这里</a> 查看。</p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold">其他网站的链接</p>
            <p class="text-block__text text-block__text--double-margin">我们的服务可能包含非由我们运营的其他网站的链接。 如果您点击第三方链接，您将被定向到该第三方的网站。 我们强烈建议您查看您访问的每个网站的隐私政策。</p>
            <p class="text-block__text text-block__text--double-margin">我们无法控制任何第三方网站或服务的内容，隐私政策或做法，也不承担任何责任。</p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold">儿童私隐</p>
            <p class="text-block__text text-block__text--double-margin">我们的服务不针对18岁以下的任何人（"儿童"）。</p>
            <p class="text-block__text text-block__text--double-margin">我们不会故意收集18岁以下任何人的个人身份信息。 如果您是家长或监护人，并且您知道您的孩子向我们提供了个人数据，请与我们联系。 如果我们意识到我们在未经父母同意的情况下收集了儿童的个人数据，我们会采取措施从我们的服务器中删除该信息。</p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold">本隐私政策的变更</p>
            <p class="text-block__text text-block__text--double-margin">我们可能会不时更新我们的隐私政策。 我们将通过在此页面上发布新的隐私政策来通知您任何更改。</p>
            <p class="text-block__text text-block__text--double-margin">在更改生效之前，我们会通过电子邮件和/或关于我们服务的显着通知通知您，并更新本隐私政策顶部的"生效日期"。</p>
            <p class="text-block__text text-block__text--double-margin">建议您定期查看本隐私政策以了解任何更改。 本隐私政策的更改在本页面上发布时生效。</p>
            <p class="text-block__text text-block__text--quarter-margin text-block__text--bold">联络我们</p>
            <p class="text-block__text text-block__text--double-margin">如果您对本隐私政策有任何疑问，请通过电子邮件与我们联系: <a class="text-block__inline-link" href="mailto:info@brightika.com">info@brightika.com</a></p>
        </section>
    </main>
</template>

<script>
import LayoutHeader from '@/app/common/pkg/LayoutHeader.vue'

export default {
    components: {LayoutHeader}
}
</script>

<style lang="scss">
@import "~@/app/styles/text-block.scss";
@import "~@/app/styles/main-content.scss";
</style>
