<template>
    <div class="contact-us-block">
        <div class="text-center">
            <h1>CONTACT US</h1>
        </div>
        <div class="container">
            <div class="row">
                <div class="col d-flex flex-column justify-content-center">
                    <div class="message-bubble">
                        <h2>Shoot me a message!</h2>
                        <div>
                            <a href="https://t.me/andriyanovd" class="telegram"></a>
                            <a href="https://www.linkedin.com/in/dmitriy-andriyanov/" class="linkedin"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="author">
            <div class="container">
                <div class="row">
                    <div class="col-auto d-flex flex-column justify-content-center pe-0">
                        <img
                            :data-src="iconThumbnailURL"
                            :data-srcset="`${iconThumbnailURL} 1x, ${iconThumbnailURL2x} 2x, ${iconThumbnailURL3x} 3x`"
                            :alt="iconAlt"
                            class="auth-avatar lazyload"
                        />
                    </div>
                    <div class="col d-flex flex-column justify-content-center pe-0">
                        <h3 v-html="author"></h3>
                        <a :href="`mailto:${authorEmail}`">{{authorEmail}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactUs',
    data() {
        return {
            iconThumbnailURL: require('@/assets/img/belgrade23/avatar/avatar.png'),
            iconThumbnailURL2x: require('@/assets/img/belgrade23/avatar/avatar@2x.png'),
            iconThumbnailURL3x: require('@/assets/img/belgrade23/avatar/avatar@3x.png'),
            iconAlt: 'Dmitrii Andriianov',
            author: 'Dmitrii Andriianov',
            authorEmail: 'publishing@brightika.com'
        }
    }
}
</script>

<style lang="scss">
@import "~@/app/styles/contact-us-block.scss";
</style>
