<template>
    <div class="scale-your-game main-page-block">
        <div class="container">
            <div class="row row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-1">
                <div class="col col-icon">
                    <div class="icon" id="ScaleYourGameIcons">
                        <img src="~@/assets/img/phone.svg" alt="Phone" class="item phone" :class="iconClass">
                        <img src="~@/assets/img/phone-elements.svg" alt="Phone elements" class="item phone-elements" :class="iconClass">
                        <img src="~@/assets/img/arrow-up.svg" alt="Arrow up" class="item arrow-up" :class="iconClass">
                    </div>
                </div>
                <div class="col d-flex align-items-center col-text">
                    <div>
                        <h1>Scale Your<br /> Game <span>With Us</span></h1>
                        <p>Our team of analysts, UA experts and<br />
                            motion designers will guide your game<br />
                            to success</p>
                        <router-link :to="{ path: '/', hash: '#contact' }" @click="routeToAnchorContact" class="btn submit">Submit Game</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Events} from '@/app/services/Events'

export default {
    data() {
        return {
            parallaxed: '',
            iconClass: '',

            elemPhone: null,
            elemPhoneElements: null,

            elemPhoneRect: null,
            elemPhoneElementsRect: null,

            mouse: {
                x: 0,
                y: 0
            }
        }
    },
    mounted() {
        this.init()

        this.windowResize()
        addEventListener('resize', this.windowResize)

        setTimeout(() => {
            this.parallaxed = 'parallaxed'
            this.applyIconClass()
            addEventListener('mousemove', this.handleParallaxEffect)
            console.log('add event listner mousemove')
        }, 100)
    },
    methods: {
        init: function() {
            this.elemPhone = document.querySelector('.phone')
            this.elemPhoneElements = document.querySelector('.phone-elements')
        },
        windowResize: function(event) {
            this.elemPhoneRect = {
                left: '50%',
                top: '50%'
            }

            this.elemPhoneElementsRect = {
                left: '50%',
                top: '50%'
            }

            this.parallaxEffect()
        },
        applyIconClass: function() {
            this.iconClass = `${this.parallaxed}`
        },
        handleParallaxEffect: function(e) {
            this.mouse.x = e.clientX
            this.mouse.y = e.clientY

            this.parallaxEffect()
        },
        parallaxEffect() {
            const xMultiplierPhone = 0.035
            const yMultiplierPhone = 0.035

            const xMultiplierPhoneElements = 0.07
            const yMultiplierPhoneElements = 0.07

            const w = window.innerWidth / 2
            const h = window.innerHeight / 2

            const mouseXPhone = (this.mouse.x - w) * xMultiplierPhone
            const mouseYPhone = (this.mouse.y - h) * yMultiplierPhone

            const mouseXPhoneElements = (this.mouse.x - w) * xMultiplierPhoneElements
            const mouseYPhoneElements = (this.mouse.y - h) * yMultiplierPhoneElements

            this.elemPhone.style.top = `calc(${this.elemPhoneRect.top} - ${mouseYPhone}px)` // + 52
            this.elemPhone.style.left = `calc(${this.elemPhoneRect.left} - ${mouseXPhone}px)` // + 50

            this.elemPhoneElements.style.top = `calc(${this.elemPhoneElementsRect.top} - ${mouseYPhoneElements}px)` // + 52
            this.elemPhoneElements.style.left = `calc(${this.elemPhoneElementsRect.left} - ${mouseXPhoneElements}px)` // + 50
        },
        routeToAnchorContact() {
            Events.registerEvent('Submit_contactus', {
                click: true
            })
        }
    }
}
</script>

<style lang="scss">
@import "~@/app/styles/scale-your-game.scss";
</style>
