export const Visibility = {
    isVisibleInViewport: function(elem) {
        const height: number = elem.offsetHeight

        let y: number = elem.offsetTop

        while (true) {
            if (elem.offsetParent === null) {
                break
            }

            elem = elem.offsetParent

            const ym: number = elem.offsetTop

            y += ym
        }

        const maxHeight: number = y + height

        return (y < (window.pageYOffset + window.innerHeight)) && (maxHeight >= window.pageYOffset)
    }
}
