<template>
    <header class="logo-header py-4 px-2">
        <nav class="logo-header__navigation">
            <div class="container">
                <div class="row">
                    <div class="col text-end">
                        <router-link class="logo-header__logo-link" to="/">
                            <img class="logo-header__logo" src="~@/assets/img/logoBTKApub.svg" alt="Brightika">
                        </router-link>
                    </div>
                    <div class="col divider text-start">
                        <router-link class="logo-header__text-link" to="/">
                            SECOND LIFE<br/>
                            FOR YOUR GAME
                        </router-link>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "../../../styles/logo-header";
</style>
