
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/scrollbar'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

// import required modules
import {Scrollbar, Mousewheel, Keyboard, Pagination, Navigation} from 'swiper'

import 'lazysizes'
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import CardSlide from '@/app/common/pkg/content/pkg/cardSlide/CardSlide.vue'

export default {
    name: 'SwiperBox',
    components: {
        CardSlide,
        Swiper,
        SwiperSlide
    },
    props: {
        galleryID: String,
        slides: Array
    },
    setup(props) {
        return {
            slidesData: props.slides,
            pagination: {
                clickable: true,
                renderBullet: function(index, className) {
                    return '<span class="' + className + '"></span>'
                }
            },
            modules: [Scrollbar, Mousewheel, Keyboard, Pagination, Navigation]
        }
    }
}
