<template>
    <LayoutHeader />
    <main class="main-content">
        <h1 class="main-content__heading">Terms of Use</h1>
        <p class="main-content__subtitle">Last Modified: October 18, 2024</p>
        <section class="main-content__text-block text-block">
            <p class="text-block__text">
                This Terms of Use ("Terms") is a legal agreement between you and Brighitka, Inc. (“Brighitka, Inc.”) and governs the use by "you" of Brighitka, Inc.
                ’s website, services, forums, games and applications (collectively, the "Services").
                By using the Services you agree to these Terms. If you do not agree to these Terms, do not purchase or use the Services.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                The terms used in our Terms and Conditions have the same meanings as in our Privacy Policy, which are accessible at
                <router-link class="text-block__inline-link" to="/privacy-policy">Privacy Policy</router-link>.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                BY USING THE SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT AND AGREE TO BE BOUND BY ITS TERMS AND CONDITIONS.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                PLEASE REVIEW BRIGHTIKA, INC.’S PRIVACY POLICY, WHICH IS INCORPORATED INTO THESE TERMS BY REFERENCE
            </p>
            <p class="text-block__text text-block__text--double-margin-colored">
                1. LICENSE AND RESTRICTIONS.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Subject to these Terms, Brighitka, Inc. grants you a non-exclusive, limited, revocable, non-transferable license to use the
                Services for your personal, non-commercial use. You may not: (a) modify, translate, reverse-engineer, reverse-compile or decompile,
                disassemble, create derivative works of, or use data gathering or extraction tools in connection with the Services; (b) reproduce, duplicate,
                copy, sell, resell, or otherwise exploit the Services, in whole or in part, for any purpose without Brighitka, Inc.’s express written consent;
                (c) frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout,
                or form) without the express written consent of the respective owner; (d) use any data mining tools or automation tools such as spiders, crawlers,
                scripts, bots, or any automated method of recording information; (e) use any meta-tags or any other "hidden text" utilizing “Brighitka, Inc.,” its
                affiliates, partners or artists without Brighitka, Inc.’s express written permission; or (f) engage in any conduct in connection with the Services
                that may be considered illegal or tortious including, without limitation, “hacking” the Services, or infringing the intellectual property or other
                proprietary rights of Brighitka, Inc. or third-parties.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Additionally, where the Services are a downloadable game and/or application (“App”), Brighitka, Inc. grants you a non-exclusive, non-transferable,
                revocable, limited license to use the App for your personal, non-commercial use on authorized devices, unless otherwise specified.
                The App may not be copied, resold, or sublicensed. These Terms apply to all versions and updates to the App, including, without limitation,
                free and paid versions.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                The App may include measures to control access to the App (including age controls), prevent unauthorized copies, or otherwise attempt to prevent anyone
                from exceeding the limited rights and licenses granted under these Terms. You may not take any action to circumvent or defeat such security features.
                If you disable or otherwise tamper with the technical protection measures, your license to use the App or other Services shall be considered automatically revoked.
            </p>
            <p class="text-block__text text-block__text--double-margin-colored">
                2. THIRD-PARTY PRODUCT, SERVICES AND LINKS.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Brighitka, Inc. may offer third-party products through the Services. You assume all risks and liabilities associated with the use of any such third-party
                products. Additionally, your rights and obligations regarding such products or services will be governed by the separate
                terms of use and privacy policies of the applicable third-party provider.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                The Services may include hyperlinks to other websites over which Brighitka, Inc. has no control.
                Brighitka, Inc. is not responsible for the availability of such external sites or resources and will not be responsible
                or liable for any content, advertising, products, or other materials on or available from such third-party websites or resources.
            </p>
            <ul class="text-block__list">
                <li class="text-block__item">
                    <p class="text-block__text">
                        Bright Data Network
                    </p>
                    <p class="text-block__text">
                        In return for some of the premium features of Brightika, Inc. games, you may choose to be a peer on the Bright Data network.
                        By doing so you agree to have read and accepted the Terms of Service of the
                        <a href="https://bright-sdk.com/eula" class="text-block__inline-link" >Bright Data SDK SLA</a> and
                        <a href="https://bright-sdk.com/privacy-policy" class="text-block__inline-link" >Bright Data’s Privacy Policy</a>.
                    </p>
                    <p class="text-block__text">
                        You may opt out of the Bright Data network by going to settings menu in the game and disabling the “BrightData Web indexing” option.
                    </p>
                </li>
            </ul>
            <p class="text-block__text text-block__text--double-margin-colored">
                3. ACCESS TO THE SERVICES.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                By using the Services, you affirm that you are at least 18 years of age (or have reached the age of majority if that is not 18 years of
                age where you live) or that you have reviewed these Terms with your parent or guardian and he or she assents to these Terms on your behalf
                and takes full responsibility for your compliance with them. If you are under the age of 13, you must have permission from your parent or
                guardian in order to use the Services. If you do not have permission from your parent of guardian, certain features and functionality of
                the Services may be disabled. Information regarding use of your personal information can be found in Brighitka, Inc.’s Privacy Policy or
                in the privacy policy specific to the Service you are using.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                You may be required to register an account before using certain Services ("Account").
                You may also be required or have the option to access the Services through a social networking service ("SNS").
                Your Account is personal to you, and you may not rent, lease, sell, trade, gift, bequeath, or otherwise transfer it to anyone else.
                You agree to provide true and complete information about yourself when you register your Account and to keep it up to date.
                You may not have more than one Account or access the Services through more than one SNS at any given time.
                You are solely responsible for maintaining the confidentiality of your password and user name and for any activities that occur under your Account.
                Brighitka, Inc. does not authorize use of automated methods to sign up for an Account, and Brighitka, Inc.
                reserves the right to immediately terminate your access to the Services or your
                Account if you breach these Terms or otherwise violate any applicable law, rule, or regulation in your jurisdiction.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                By using the Services and providing us with your contact information, you explicitly consent to receive communications from us.
                If you would not like to receive such communications, please review Brighitka, Inc.’s Privacy Policy and opt-out procedures.
            </p>
            <p class="text-block__text text-block__text--double-margin-colored">
                4. IN-GAME VIRTUAL CURRENCY AND VIRTUAL ITEMS.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                The Service may include an opportunity for you to earn, buy or otherwise obtain in-game currency ("Virtual Currency") to access virtual,
                in-game digital goods or items ("Virtual Items"). Methods of obtaining Virtual Currency are at Brighitka, Inc.’s sole discretion.
                Virtual Currency and Virtual Items are not real currency, do not have monetary value, and may not be redeemed for legal currency,
                or items of value outside of the Services.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Virtual Currency and Virtual Items obtained via the Service are provided to you under a limited, personal, revocable,
                non-transferable, non-sublicenseable license to use within the Service. You have no property interest, right or title in or
                to any Virtual Currency or Virtual Items appearing or originating in the Service, and they may not be transferred or resold in
                any manner, including, without limitation, by means of any direct sale or auction service.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                You agree that all sales of Virtual Currency and Virtual Items are final. No refunds will be given, except in Brighitka, Inc.’s sole
                and absolute discretion. Brighitka, Inc. has no liability for hacking or loss of your Virtual Currency or Virtual Items or
                any goods or services obtained via Virtual Currency. Brighitka, Inc. has no obligation to, and will not, reimburse you for any
                Virtual Currency or Virtual Items obtained via Virtual Currency. Brighitka, Inc. reserves the right, in its sole discretion and
                without prior notification, to change or limit the price, availability, or order quantity of any Virtual Currency or Virtual Items
                and to refuse to provide you with any Virtual Currency or Virtual Items.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Virtual Currency may have an expiration date. If your Virtual Currency or Virtual Items expire and you still have an active
                Account, Brighitka, Inc. may offer conversion of such Virtual Currency into Virtual Items for you in its sole discretion,
                however Brighitka, Inc. is under no obligation to do so. Virtual Currency and Virtual Items may be forfeited if: (a) your Account
                or access to the Services is terminated or suspended for any reason; (b) you breach these Terms; (c) Brighitka, Inc. discontinues
                availability of the Service; or (d) you disassociate from the Service.
            </p>
            <p class="text-block__text text-block__text--double-margin-colored">
                5. ADVANCEMENTS, QUALITY AND DEFICIENCIES, ROLL-BACK AND BETA TESTING.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                In exceptional cases such as within the framework of updates or error rectifications, it may be required to reset the
                App to an earlier status in order to prevent any greater damage (in these Terms and Conditions referred to as "Roll-back").
                In this context, the App progress, App score, high scores and achievements/accomplishments, etc. are reset to a corresponding earlier
                status; any changes in the interim or any developments are lost. Any real money purchases you made in the period of time
                between the time when the Roll-Back is initiated and the time that the App system is rolled back to will not be refunded,
                but instead be given to you once more as part of the Roll-Back. Your further claims are excluded unless otherwise specified
                in these Terms and Conditions. Brighitka, Inc. is entitled but not obliged to grant its users a voluntary bonus e.g.
                in form of virtual currency or premium features. It is at Brighitka, Inc. discretion to grant such a bonus and it does not
                oblige Brighitka, Inc. to grant a bonus during future rollbacks to other users or the same users.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Brighitka, Inc. can offer you for the purpose of testing participation in or use of the App, its elements, Services and
                contents that are not smoothly running yet (in these Terms and Conditions referred to as «Beta»).
                Beta can include programming errors - even serious ones - known or unknown to Brighitka, Inc.
                These may lead to the App’s or your terminal to crash. Within the framework of Beta, Brighitka, Inc.
                may delete or add contents at any time among others to search for errors and to improve the game experience.
                Furthermore, it can reset game scores, install backups or create specific game scores. You are not entitled to the
                restoration of a specific game status. Brighitka, Inc. can extend, shorten any Beta in whole or in part at any time.
            </p>
            <p class="text-block__text text-block__text--double-margin-colored">
                6. ONLINE CONTENT AND CONDUCT.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                The Services may include opportunities, through your Account or SNS, to chat or participate in blogs, message boards,
                online forums and other functions that provide you with the opportunity to create, submit, post, display, transmit, perform,
                publish, or distribute to the world ("Forum(s)") links, writings, photos, graphics, or other material ("Forum Content").
                All Forum Content posted by you to any Forum shall be your sole responsibility.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                The Forums shall be used only in a noncommercial manner. Brighitka, Inc. has the right, but not the responsibility,
                to monitor or remove Forum Content that Brighitka, Inc. believes to be harmful, offensive, or otherwise violates these Terms,
                without liability to you. Brighitka, Inc. in its sole discretion, reserves the right to restrict or disable your use of the Forums.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                By submitting Forum Content to any Forum, you warrant that you have all necessary rights to submit the Forum Content without any
                restrictions of any kind, and grant to Brighitka, Inc. a fully paid, royalty-free, non-exclusive, irrevocable, worldwide, unconditional,
                perpetual, right and license to use, reproduce, modify, publicly display and perform, make derivatives works of, sublicense,
                and distribute all such Forum Content in all media now known or later developed, and/or to incorporate such materials
                in other works in any form, media, or technology now known or later developed.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Any opinions, advice, statements, services, offers or other information or content expressed or made available by
                third-parties in the Forums, are those of the respective authors and not of Brighitka, Inc. or its affiliates, and Brighitka, Inc.
                is not responsible for the accuracy or reliability of same. Brighitka, Inc. makes no representations or warranties regarding the Forums.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                All Forum Content posted by you to any Forum shall be your sole responsibility.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                You understand that you may be exposed to Forum Content and actions from other users that are indecent, explicit, offensive,
                or which otherwise violates these Terms (“Inappropriate Activity”). You can report actual or suspected Inappropriate Activity to
                <a class="text-block__inline-link" href="mailto:mailto:info@brightika.com">info@brightika.com</a>.
                Brighitka, Inc. reserves the right, in its sole discretion, to take any action against Inappropriate Activity that Brighitka, Inc.
                believes to be harmful, offensive, or otherwise violates these Terms. You agree that Brighitka, Inc. will not be liable to you or
                any third-party in any way for Forum Content, Inappropriate Activity, or for any damage or loss therefrom.
            </p>
            <p class="text-block__text text-block__text--double-margin-colored">
                7. TITLE.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Unless otherwise specified in these Terms, any and all title, ownership, rights, and intellectual property rights in and to the
                Services including all content therein (collectively the “Brighitka, Inc. Content”) shall remain in Brighitka, Inc. and/or
                its suppliers, and are protected by the copyright laws. You may not use, copy, transmit, modify, distribute, or create any
                derivative works from the Services or the Brighitka, Inc. Content without prior written consent from Brighitka, Inc.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                The Services may allow you to create content (“Your Content”) that incorporates the Brighitka, Inc.
                Content, including, but not limited, to photos incorporating elements or graphics from games, screenshots or a video
                of your game play. Brighitka, Inc. retains all rights, title and interest in and to the Brighitka, Inc. Content.
                You retain all rights to Your Content; provided, however, that Brighitka, Inc. shall have an exclusive, perpetual,
                irrevocable, fully transferable and sub-licensable worldwide right and license to Your Content for any purpose,
                including, but not limited to, the rights to reproduce, copy, adapt, modify, perform, display, publish, broadcast,
                transmit, or otherwise communicate to the public by any means whether now known or unknown and distribute
                Your Content without any further notice or compensation to you. Except where prohibited by law, you
                hereby waive any moral rights of paternity, publication, reputation, or attribution with respect to
                Brighitka, Inc.’s use of Your Content.
            </p>
            <p class="text-block__text text-block__text--double-margin-colored">
                8. DISCLAIMER OF WARRANTY AND LIMIT OF LIABILITY.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                BRIGHTIKA, INC. MAKES NO REPRESENTATION, WARRANTY, AND/OR GUARANTEE OF THE SUITABILITY OF THE SERVICES FOR YOUR PURPOSES,
                OR THAT THE USE OF THE SAME WILL BE SECURE, UNINTERRUPTED, ERROR-FREE, FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS,
                OR FUNCTION PROPERLY IN COMBINATION WITH ANY THIRD-PARTY TECHNOLOGY, HARDWARE, SOFTWARE, SYSTEMS OR DATA.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                THE SERVICES ARE PROVIDED “AS IS" AND ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED,
                STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, TITLE, FITNESS
                FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT ARE HEREBY DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
                LAW. FURTHER, BRIGHTIKA, INC. MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE
                OPERATION OF THE SAME, OR TO THE INFORMATION, CONTENT, COMMUNICATIONS, MATERIALS, OR PRODUCTS AND SERVICE CONTAINED THEREIN.
                YOUR USE OF THE BRIGHTIKA, INC. SERVICES IS AT YOUR SOLE RISK.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                BRIGHTIKA, INC. WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM YOUR USE OF THE SERVICES, INCLUDING,
                WITHOUT LIMITATION, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE AND CONSEQUENTIAL DAMAGES. CERTAIN REGIONAL LAWS DO NOT
                ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU,
                SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                YOU FURTHER ACKNOWLEDGE THAT BRIGHTIKA, INC. IS NOT LIABLE, AND YOU AGREE NOT TO HOLD BRIGHTIKA, INC. LIABLE,
                FOR THE CONDUCT OF THIRD-PARTIES, INCLUDING OTHER USERS OF THE SERVICES AND OTHER THIRD-PARTY SITES, AND THAT
                THE RISK OF USING OR ACCESSING THE SERVICES, AND OF INJURY FROM THE FOREGOING, RESTS ENTIRELY WITH YOU.
            </p>
            <p class="text-block__text text-block__text--double-margin-colored">
                9. TERMINATION.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                These Terms will automatically terminate if you fail to comply with any term hereof. You and Brighitka, Inc.
                have the right to terminate or cancel your Account, at any time for any reason and without notice.
                Upon termination you shall immediately discontinue use of the Services.
                Your obligations under Sections 6, 7, and 12 shall survive any termination.
            </p>
            <p class="text-block__text text-block__text--double-margin-colored">
                10. NO ASSIGNMENT.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                These Terms and the licenses granted to you herein are personal to you, and may not be assigned prior to
                obtaining Brighitka, Inc.s express written consent.
            </p>
            <p class="text-block__text text-block__text--double-margin-colored">
                11. INFRINGEMENT NOTIFICATION.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Any claims related to the Services should be addressed to:
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Brightika, Inc.
            </p>
            <p class="text-block__text text-block__text--quoter-margin">
                700 N. Fairfax Suite 614
            </p>
            <p class="text-block__text text-block__text--quoter-margin">
                Alexandria VA, 22314
            </p>
            <p class="text-block__text text-block__text--quoter-margin">
                <a class="text-block__inline-link" href="mailto:mailto:info@brightika.com">info@brightika.com</a>
            </p>
            <p class="text-block__text text-block__text--double-margin-colored">
                12. GENERAL.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                These Terms, along with the Privacy Policy, and any other privacy policy applicable to the specific Services
                you are using, constitute the complete and final agreement between Brighitka, Inc. and you with respect to
                your use of the Services and may not be modified by you except in a writing duly signed by you and an authorized
                representative of Brighitka, Inc.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                Brighitka, Inc. may make changes to the Services, these Terms, or its Privacy Policy from time to time. Brighitka, Inc.
                will use reasonable efforts to provide you with notice of any material changes. All such changes will become
                effective immediately upon notice and/or posting. If any of these changes are deemed invalid, void, or for any
                reason unenforceable, that change will be deemed severable and will not affect the validity and enforceability of
                any unchanged portions of the Services, these Terms, or the Privacy Policy. Brighitka, Inc. may transfer or assign
                the Services, these Terms, or the Privacy Policy, in whole or in part, to any third-party of Brighitka, Inc.’s choosing.
                These Terms will insure to the benefit of and be binding upon Brighitka, Inc.’s respective successors and assigns.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                In the event that you take legal action, a suit must be filed before a сourt of the Virginia, USA.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                In certain circumstances, you have the following data protection rights:
            </p>
            <ul class="text-block__list">
                <li class="text-block__item">
                    <p class="text-block__text">
                        The right to access, update or to delete the information we have on you.
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        Whenever made possible, you can access, update or request deletion of your Personal Data.
                        To perform these actions, please contact us to assist you.
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        The right of rectification. You have the right to have your information rectified if that information
                        is inaccurate or incomplete.
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        The right to object. You have the right to object to our processing of your Personal Data.
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        The right of restriction. You have the right to request that we restrict the processing of your personal information.
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        The right to data portability. You have the right to be provided with a copy of the information we
                        have on you in a structured, machine-readable and commonly used format.
                    </p>
                </li>
                <li class="text-block__item">
                    <p class="text-block__text">
                        The right to withdraw consent. You also have the right to withdraw your consent at any time where
                        Brightika, Inc. relied on your consent to process your personal information.
                    </p>
                </li>
            </ul>
            <p class="text-block__text text-block__text--double-margin">
                Please note that we may ask you to verify your identity before responding to such requests.
            </p>
            <p class="text-block__text text-block__text--double-margin-colored">
                CONTACT US.
            </p>
            <p class="text-block__text text-block__text--double-margin">
                If you have questions regarding these Terms, please contact us at:
            </p>
            <p class="text-block__text text-block__text--double-margin">
                © Brighitka, Inc.
            </p>
            <p class="text-block__text text-block__text--quoter-margin">
                E-mail:
                <a class="text-block__inline-link" href="mailto:mailto:info@brightika.com">info@brightika.com</a>
            </p>
            <p class="text-block__text text-block__text--double-margin">
                If you have questions about Privacy Policy, please contact us by email:
                <a class="text-block__inline-link" href="mailto:mailto:info@brightika.com">info@brightika.com</a>
            </p>
            <p class="text-block__text text-block__text--quarter-margin-center">
                © 2024 Brightika, Inc. All Rights Reserved. <router-link class="text-block__inline-link" to="/privacy-policy/">Privacy Policy</router-link>
            </p>
        </section>
    </main>
</template>

<script>
import LayoutHeader from '@/app/common/pkg/LayoutHeader.vue'

export default {
    components: {LayoutHeader}
}
</script>

<style lang="scss">
@import "~@/app/styles/text-block.scss";
@import "~@/app/styles/main-content.scss";
</style>
