<template>
    <div class="we-publish-games main-page-block">
        <div class="icons" id="WePublishGames">
            <img src="~@/assets/img/logo.svg" alt="Brightika" class="item logo" :class="iconClass" width="240">
            <h1 class="item head" :class="iconClass">We Publish<br/>Games</h1>
            <img src="~@/assets/img/coin.svg" alt="Coin" class="item coin-left" :class="iconClass">
            <img src="~@/assets/img/coin.svg" alt="Coin" class="item coin-right" :class="iconClass">
            <img src="~@/assets/img/star.svg" alt="Star" class="item star" :class="iconClass">
            <img src="~@/assets/img/cup.svg" alt="Cup" class="item cup" :class="iconClass">
            <img src="~@/assets/img/person.svg" alt="Person" class="item person" :class="iconClass">
        </div>
    </div>
</template>

<script>
import {Visibility} from '@/app/services/Visibility'

export default {
    data() {
        return {
            showed: '',
            parallaxed: '',
            iconClass: '',

            elemCup: null,
            elemStar: null,
            elemPerson: null,

            elemCupRect: null,
            elemStarRect: null,
            elemPersonRect: null,

            mouse: {
                x: 0,
                y: 0
            }
        }
    },
    mounted() {
        this.init()

        this.windowResize()
        addEventListener('resize', this.windowResize)

        this.handleScroll()
        addEventListener('scroll', this.handleScroll)

        setTimeout(() => {
            this.parallaxed = 'parallaxed'
            this.applyIconClass()
            addEventListener('mousemove', this.handleParallaxEffect)
            console.log('add event listner mousemove')
        }, 3300)
    },
    methods: {
        init: function() {
            this.elemCup = document.querySelector('.cup')
            this.elemStar = document.querySelector('.star')
            this.elemPerson = document.querySelector('.person')
        },
        windowResize: function(event) {
            if (window.innerWidth < 768) {
                this.elemCupRect = {
                    top: '74%',
                    left: '15%'
                }

                this.elemStarRect = {
                    top: '59%',
                    left: '85%'
                }

                this.elemPersonRect = {
                    top: '10%',
                    left: '87%'
                }
            } else {
                this.elemCupRect = {
                    top: '60%',
                    left: '30.35%'
                }

                this.elemStarRect = {
                    top: '44%',
                    left: '74.5%'
                }

                this.elemPersonRect = {
                    top: '18%',
                    left: '69.5%'
                }
            }

            this.parallaxEffect()
        },
        applyIconClass: function() {
            this.iconClass = `${this.showed} ${this.parallaxed}`
        },
        handleScroll: function() {
            if (this.iconClass === '') {
                const element = document.getElementById('WePublishGames')
                if (Visibility.isVisibleInViewport(element)) {
                    this.showed = 'showed'
                    this.applyIconClass()
                }
            }
        },
        handleParallaxEffect: function(e) {
            this.mouse.x = e.clientX
            this.mouse.y = e.clientY

            this.parallaxEffect()
        },
        parallaxEffect() {
            const xMultiplier = 0.07
            const yMultiplier = 0.07

            const w = window.innerWidth / 2
            const h = window.innerHeight / 2

            const mouseX = (this.mouse.x - w) * xMultiplier
            const mouseY = (this.mouse.y - h) * yMultiplier

            this.elemCup.style.top = `calc(${this.elemCupRect.top} - ${mouseY}px)` // + 52
            this.elemCup.style.left = `calc(${this.elemCupRect.left} - ${mouseX}px)` // + 50

            this.elemPerson.style.top = `calc(${this.elemPersonRect.top} - ${mouseY}px)` // + 36
            this.elemPerson.style.left = `calc(${this.elemPersonRect.left} - ${mouseX}px)` // + 33

            this.elemStar.style.top = `calc(${this.elemStarRect.top} - ${mouseY}px)` // + 27
            this.elemStar.style.left = `calc(${this.elemStarRect.left} - ${mouseX}px)` // + 28
        }
    }
}
</script>

<style lang="scss">
@import "~@/app/styles/we-publish-games.scss";
</style>
