<template>
    <router-view />
    <LayoutFooter />
    <img
        id="ScrollToTop"
        class="scroll-to-top"
        src="~@/assets/img/scroll-to-top.svg"
        alt="Scroll To Top"
        width="70"
        height="70"
        :class="classScrollToTop"
        @click="scrollToTop"
    />
</template>

<script>
import 'bootstrap'
import LayoutFooter from '@/app/common/pkg/LayoutFooter.vue'
export default {
    components: {LayoutFooter},
    data() {
        return {
            scrollTimer: 0,
            scrollPosition: 0
        }
    },
    computed: {
        classScrollToTop() {
            return {
                visible: this.scrollPosition > 300
            }
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleScroll: function() {
            if (this.scrollTimer) return
            this.scrollTimer = setTimeout(() => {
                this.scrollPosition = window.scrollY
                clearTimeout(this.scrollTimer)
                this.scrollTimer = 0
            }, 100)
        },
        scrollToTop: function() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@400;500;700&display=swap');
@import "bootstrap/dist/css/bootstrap.min.css";
// @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
// @import "app/styles/normalize";
// @import "app/styles/custom";
@import "~@/app/styles/scroll-to-top.scss";
</style>
